import './../styles/Footer.scss';
import logo from './../assets/solpath-footer-logo.png';
import instagram from './../assets/instagram.png';
import linkedIn from './../assets/linkedIn.png';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import PrivacyPolicyModal from './modals/PrivacyPolicyModal';

const Footer = ({
  homeRef,
  whoWeAreRef,
  analyticsRef,
  featuresRef,
  faqsRef,
  serviceRef,
  testimonialsRef,
  isMobile,
}: {
  homeRef: React.RefObject<HTMLElement>;
  featuresRef: React.RefObject<HTMLElement>;
  whoWeAreRef: React.RefObject<HTMLElement>;
  analyticsRef: React.RefObject<HTMLElement>;
  faqsRef: React.RefObject<HTMLElement>;
  serviceRef: React.RefObject<HTMLElement>;
  testimonialsRef: React.RefObject<HTMLElement>;

  isMobile: boolean;
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const navigate = useNavigate();

  return (
    <>
      <PrivacyPolicyModal showModal={showModal} handleClose={handleClose} />
      <div className="footer-view">
        <div className="footer">
          <div className="website-info-links">
            {isMobile === false ? (
              <div className="logo-terms">
                <img className="solvpath-logo" src={logo} alt="logo" onClick={() => navigate('/')} />
              </div>
            ) : (
              <img className="solvpath-logo" src={logo} alt="logo" onClick={() => navigate('/')} />
            )}

            <div className="social-links">
              <img
                src={instagram}
                alt="instagram"
                onClick={() => window.open('https://www.instagram.com/solvpath/', '_blank')}
              ></img>
              <img
                src={linkedIn}
                alt="linkedIn"
                onClick={() => window.open('https://www.linkedin.com/company/solvpath/', '_blank')}
              ></img>
            </div>
          </div>

          {isMobile && (
            <div className="terms-mobile">
              <span onClick={handleShow}>Terms and Privacy</span>
              <span className="cookies">Cookies</span>
            </div>
          )}

          {!isMobile ? (
            <div className="rights-desktop">
              <div className="terms-desktop">
                <span onClick={handleShow}>Terms and Privacy</span>
                <span>Cookies</span>
              </div>
              <div className="rights-reserved">
                <p>© 2024 solvpath, LLC All right reserved</p>
              </div>
            </div>
          ) : (
            <div className="rights">
              <p>© 2024 solvpath, LLC All right reserved</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Footer;
