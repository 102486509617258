import "./../styles/Booking.scss";

import logo from "../assets/solvpath-logo.png";
import { useEffect } from "react";

const Booking = ({ url }: { url: string }) => {
  useEffect(() => {
    if (!document.getElementById("hubspot-meetings-script")) {
      const script = document.createElement("script");

      script.src =
        "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";

      script.type = "text/javascript";
      script.id = "hubspot-meetings-script";

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  return (
    <div className="booking-view">
      <div className="header">
        <img src={logo} alt="logo" />
        <h3>Schedule a Demo</h3>
        <span>
          Discover our suite of products for efficient customer support
          automation
        </span>
      </div>
      <div className="meetings-iframe-container" data-src={url}></div>
    </div>
  );
};

export default Booking;
