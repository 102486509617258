import { useEffect, useRef, useState } from 'react';
import './App.scss';
import Header from './components/Header';
import Home from './pages/Home';
import Footer from './components/Footer';
import ScrollWidget from './components/ScrollWidget';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Booking from './pages/Booking';
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  const homeRef = useRef<HTMLDivElement>(null);
  const whoWeAreRef = useRef<HTMLDivElement>(null);
  const featuresRef = useRef<HTMLDivElement>(null);
  const analyticsRef = useRef<HTMLDivElement>(null);
  const serviceRef = useRef<HTMLDivElement>(null);
  const testimonialsRef = useRef<HTMLDivElement>(null);
  const faqsRef = useRef<HTMLDivElement>(null);
  const [isWidgetVisible, setIsWidgetVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1026);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1026);
    };

    window.scrollTo(0, 0);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.defer = true;
    script.src =
      "//cdn.mouseflow.com/projects/41f185f4-af18-4248-b5d0-3ade128889b4.js";
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition =
        (window.scrollY / document.documentElement.scrollHeight) * 100;

      if (scrollPosition > 30) {
        setIsWidgetVisible(true);
      } else {
        setIsWidgetVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const commonElement = (
    <>
      <Header
        homeRef={homeRef}
        whoWeAreRef={whoWeAreRef}
        featuresRef={featuresRef}
        serviceRef={serviceRef}
        analyticsRef={analyticsRef}
        testimonialsRef={testimonialsRef}
        faqsRef={faqsRef}
        isMobile={isMobile}
      />
      <Home
        homeRef={homeRef}
        whoWeAreRef={whoWeAreRef}
        featuresRef={featuresRef}
        serviceRef={serviceRef}
        analyticsRef={analyticsRef}
        testimonialsRef={testimonialsRef}
        faqsRef={faqsRef}
        isMobile={isMobile}
      />
      <Footer
        homeRef={homeRef}
        whoWeAreRef={whoWeAreRef}
        featuresRef={featuresRef}
        faqsRef={faqsRef}
        serviceRef={serviceRef}
        analyticsRef={analyticsRef}
        testimonialsRef={testimonialsRef}
        isMobile={isMobile}
      />
      {isWidgetVisible && <ScrollWidget />}
    </>
  );

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/tsn" element={commonElement} />
          <Route
            path="/tsn/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/tsn?embed=true" />
            }
          />

          <Route path="/tbb" element={commonElement} />
          <Route
            path="/tbb/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/tbb?embed=true" />
            }
          />

          <Route path="/ht24" element={commonElement} />
          <Route
            path="/ht24/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/ht24?embed=true" />
            }
          />

          <Route path="/cc" element={commonElement} />
          <Route
            path="/cc/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/cc?embed=true" />
            }
          />

          <Route path="/24sgc" element={commonElement} />
          <Route
            path="/24sgc/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/24sgc?embed=true" />
            }
          />
          
          <Route path="/buyist" element={commonElement} />
          <Route
            path="/buyist/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/buyist?embed=true" />
            }
          />

          <Route path="/outbrain" element={commonElement} />
          <Route
            path="/outbrain/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/outbrain?embed=true" />
            }
          />

          <Route path="/noer" element={commonElement} />
          <Route
            path="/noer/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/noer?embed=true" />
            }
          />

          <Route path="/d3" element={commonElement} />
          <Route
            path="/d3/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/d3?embed=true" />
            }
          />
          
          <Route path="/tes" element={commonElement} />
          <Route
            path="/tes/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/tes?embed=true" />
            }
          />

          <Route path="/rsm" element={commonElement} />
          <Route
            path="/rsm/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/rsm?embed=true" />
            }
          />

          <Route path="/scaleops" element={commonElement} />
          <Route
            path="/scaleops/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/scaleops?embed=true" />
            }
          />

          <Route path="/forrester" element={commonElement} />
          <Route
            path="/forrester/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/forrester?embed=true" />
            }
          />

          <Route path="/payshield" element={commonElement} />
          <Route
            path="/payshield/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/payshield?embed=true" />
            }
          />

          <Route path="/elite" element={commonElement} />
          <Route
            path="/elite/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/elite?embed=true" />
            }
          />

          <Route path="/econnects" element={commonElement} />
          <Route
            path="/econnects/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/econnects?embed=true" />
            }
          />

          <Route path="/mak" element={commonElement} />
          <Route
            path="/mak/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/mak?embed=true" />
            }
          />

          <Route path="/ripple" element={commonElement} />
          <Route
            path="/ripple/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/ripple?embed=true" />
            }
          />

          <Route path="/tml" element={commonElement} />
          <Route
            path="/tml/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/tml?embed=true" />
            }
          />

          <Route path="/von" element={commonElement} />
          <Route
            path="/von/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/von?embed=true" />
            }
          />

          <Route path="/xcaliber" element={commonElement} />
          <Route
            path="/xcaliber/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/xcaliber?embed=true" />
            }
          />

          <Route path="/mx" element={commonElement} />
          <Route
            path="/mx/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/mx?embed=true" />
            }
          />

          <Route path="/rb" element={commonElement} />
          <Route
            path="/rb/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/rb?embed=true" />
            }
          />

          <Route path="/neo" element={commonElement} />
          <Route
            path="/neo/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/neo?embed=true" />
            }
          />

          <Route path="/ignite" element={commonElement} />
          <Route
            path="/ignite/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/ignite?embed=true" />
            }
          />

          <Route path="/winstar" element={commonElement} />
          <Route
            path="/winstar/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/winstar?embed=true" />
            }
          />

          <Route path="/sae" element={commonElement} />
          <Route
            path="/sae/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/sae?embed=true" />
            }
          />

          <Route path="/mokum" element={commonElement} />
          <Route
            path="/mokum/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/mokum?embed=true" />
            }
          />
          
          <Route path="/integral" element={commonElement} />
          <Route
            path="/integral/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/integral?embed=true" />
            }
          />

          <Route path="/yeetiq" element={commonElement} />
          <Route
            path="/yeetiq/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/yeetiq?embed=true" />
            }
          />

          <Route path="/logicall" element={commonElement} />
          <Route
            path="/logicall/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/logicall?embed=true" />
            }
          />

          <Route path="/tfl" element={commonElement} />
          <Route
            path="/tfl/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/tfl?embed=true" />
            }
          />

          <Route path="/vrio" element={commonElement} />
          <Route
            path="/vrio/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/vrio?embed=true" />
            }
          />

          <Route path="/sticky" element={commonElement} />
          <Route
            path="/sticky/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/sticky?embed=true" />
            }
          />

          <Route path="/kk" element={commonElement} />
          <Route
            path="/kk/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/kk?embed=true" />
            }
          />

          <Route
            path="/"
            element={
              <>
                <Header
                  homeRef={homeRef}
                  whoWeAreRef={whoWeAreRef}
                  featuresRef={featuresRef}
                  serviceRef={serviceRef}
                  analyticsRef={analyticsRef}
                  testimonialsRef={testimonialsRef}
                  faqsRef={faqsRef}
                  isMobile={isMobile}
                />
                <Home
                  homeRef={homeRef}
                  whoWeAreRef={whoWeAreRef}
                  featuresRef={featuresRef}
                  serviceRef={serviceRef}
                  analyticsRef={analyticsRef}
                  testimonialsRef={testimonialsRef}
                  faqsRef={faqsRef}
                  isMobile={isMobile}
                />
                <Footer
                  homeRef={homeRef}
                  whoWeAreRef={whoWeAreRef}
                  featuresRef={featuresRef}
                  faqsRef={faqsRef}
                  serviceRef={serviceRef}
                  analyticsRef={analyticsRef}
                  testimonialsRef={testimonialsRef}
                  isMobile={isMobile}
                />
                {isWidgetVisible && <ScrollWidget />}
              </>
            }
          />
          <Route
            path="/booking/*"
            element={
              <Booking url="https://meetings.hubspot.com/solvpath/meeting?embed=true" />
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
