import React, { useEffect, useRef } from 'react';
import { Testimonial } from '../utils/models';
import './../styles/VideoPlayer.scss';

const VideoPlayer: React.FC<{
  videoData: Testimonial;
  isMobile: boolean;
  updateTestimonials: (id: string, isPlaying: boolean) => void;
}> = ({ videoData, isMobile, updateTestimonials }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    const handlePlay = () => {
      if (videoElement) {
        document.querySelectorAll('video').forEach((video) => {
          if (video !== videoElement && !video.paused) {
            video.pause();
          }
        });
        updateTestimonials(videoData.id, true);
      }
    };

    if (videoElement) {
      videoElement.addEventListener('play', handlePlay);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('play', handlePlay);
      }
    };
  }, [videoData, updateTestimonials]);

  return (
    <div className="testimonial">
      <div className="video-container">
        {!videoData.isPlaying && (
          <button
            className="play-icon"
            onClick={() => videoRef.current?.play()}
          >
            <svg
              viewBox="0 0 448 512"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              width="26px"
            >
              <path
                d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
                fill="currentColor"
              ></path>
            </svg>
          </button>
        )}
        <video
          controls={videoData.isPlaying}
          width="100%"
          poster={videoData.thumbnail}
          ref={videoRef}
          src={videoData.videoUrl}
          onEnded={() => videoRef.current?.pause()}
        >
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="quote-container">
        <div className="quote">{videoData.quote}</div>
      </div>

      <div className="client-info">
        <span className="name">{videoData.clientName}</span>
        <span className="designation">{videoData.clientDesignation}</span>
        {videoData.companyName && <span>{videoData.companyName}</span>}
        {videoData.logo && (
          <img
            className="company-logo"
            src={videoData.logo}
            alt="logo"
            style={
              videoData.id === 'greenApe' && isMobile
                ? { width: '46%', maxWidth: '220px' }
                : videoData.id === 'greenApe' && !isMobile
                ? { width: '26%' }
                : videoData.id === 'westmount' && !isMobile
                ? { width: '30%' }
                : videoData.id === 'westmount' && isMobile
                ? { width: '50%' }
                : {}
            }
          ></img>
        )}
      </div>
    </div>
  );
};

export default VideoPlayer;
