import '../../styles/PrivacyPolicyModal.scss';
import { Modal } from 'react-bootstrap';

import logo from '../../assets/solvpath-logo.png';

const PrivacyPolicyModal = ({ showModal, handleClose }: PropTypes) => {
  return (
    <Modal show={showModal} onHide={handleClose} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          <b>Privacy Policy</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="privacy-policy-container">
        <div className="privacy-policy-view">
          <div className="logo-container">
            <img src={logo} alt="logo" />
          </div>
          <h4 className="heading">END USER LICENSE AGREEMENT</h4>
          <p>
            This End User License Agreement is a legally binding agreement between the purchaser and/or end user (“You”,
            Subscriber”, “Your”), and solvpath LLC (“solvpath”), a New York Limited Liability Company. By clicking
            “select plan”, “activate now”, “submit”, “order now”, or “purchase” to open an account with solvpath, or any
            other application made available by solvpath (“Software”), or by your use of the Software, You acknowledge
            that You have read, understood and agree to the terms and conditions of this Agreement with solvpath. If you
            are unwilling to accept the terms and conditions of this Agreement, you may not use the Software. You use.
            Both solvpath and Subscriber may be referred to herein individually as a “Party” and/or collectively as the
            “Parties.”
            <br /> This Agreement includes and incorporates the attached Exhibits.
          </p>
          <p>
            solvpath owns, operates and provides certain proprietary Software and technology that it will make available
            to Subscriber on a limited basis, as set forth below, for the provision of subscription services to be used
            by Subscriber for, among other things, automated customer service support, troubleshooting and reporting,
            and fully automated outbound campaigns (hereinafter referred to as the “Service(s),” as further set forth
            below). The Software is made available by solvpath to the Subscriber through the website located at
            <br />
            https://solvpath.com <strong>(“Site”).</strong>
          </p>
          <p>
            Each Party agrees that any of its representatives, employees, affiliates, members, or any person or entity
            acting on its behalf with respect to the provision of or use of the Services shall be bound by, and shall
            abide by, the terms of this Agreement. You further agree that you are bound by the terms of this Agreement
            whether you are acting on your own behalf or on behalf of a third party.
          </p>
          <h5>
            <strong>Definitions.</strong>
          </h5>
          <p>
            <strong>Affiliate:</strong> means any entity that directly or indirectly controls, is controlled by, or is
            under common control with you. “Control,” for purposes of this definition, means direct or indirect
            ownership or control of more than 50% of your voting interests.
          </p>
          <p>
            <strong>Authorized User:</strong> means those licensed uniquely identified individuals who are authorized by
            you to use the Software regardless of whether those individuals are actively using the Software at any given
            time. Licenses granted on an Authorized User basis may be permanently reassigned between uniquely identified
            individuals over time, subject to solvpath’s prior written approval, but may not be reassigned so frequently
            as to enable the sharing of a single license between multiple users.
          </p>
          <p>
            <strong>Subscriber Data:</strong> means data generated by you or your Authorized User and used by or
            imported into the Software, but excludes data generated by a Client Sublicensee unless such data is combined
            with your data or is relevant to your provision of services to such Client Sublicensee.
          </p>
          <p>
            <strong>Documentation:</strong> means any supporting product help and technical specifications documentation
            provided by solvpath with the Software to you.
          </p>
          <p>
            <strong>Software:</strong> means the proprietary solvpath software product(s) and technology provided in
            connection with this Agreement in object code form (or as otherwise specified in any related Ordering
            Document), as more fully described in the Documentation. “Software” shall also include any Support and
            Maintenance Services releases provided to you under this Agreement. Unless otherwise noted, the Software and
            Documentation are referred to collectively herein as “Software”. All undefined names of Software products
            have the meanings given to them in the Documentation.
          </p>
          <h5>
            <strong>1. SaaS Services and Support.</strong>
          </h5>
          <p>
            1.1 This Agreement sets forth the terms and conditions under which solvpath agrees to license to Subscriber
            certain hosted Software and provide all other services necessary for the productive use of such Software,
            including, but not limited to, customization/integration, user identification and password change
            management, data import/export, monitoring, technical support, maintenance, training, backup and recovery
            and change management (the <strong>“Services”</strong>).
          </p>
          <p>
            1.1.1 Subject to the terms of this Agreement, solvpath will use commercially reasonable efforts to provide
            Subscriber the Services and in accordance with the Service Level Terms attached hereto as <br />
            <strong>Exhibit A.</strong>
          </p>
          <p>
            1.1.2 As part of the registration process, Subscriber will identify an administrative user name(s) and
            password(s) for Subscriber’s account (“Authorized User(s)”). Subscriber shall be responsible for (a)
            providing true, accurate, current and complete information in all material respects; (b) verifying the
            Authorized User(s) and updating such information on a regular basis; and (c) notifying solvpath immediately
            of an unauthorized use of the Software or Services of which Subscriber becomes aware.
          </p>
          <p>
            1.1.3 solvpath shall have the right to request from Subscriber its certification of compliance with the
            permitted number of Authorized Users (which are subject to solvpath’s sole discretion). Where the actual
            number of users exceeds the permitted number of Authorized Users, solvpath reserves the right, at its sole
            discretion, to terminate Subscriber’s account and refuse any and all current or future use of the Services.
          </p>
          <p>1.1.4 solvpath reserves the right to refuse registration of or cancel passwords it deems inappropriate.</p>
          <p>
            1.1.5 If any information provided by Subscriber is untrue, inaccurate, not current or incomplete in any
            material respect, solvpath has the right to terminate Subscriber’s account and refuse any and all current or
            future use of the Services.
          </p>
          <p>
            1.2 Subject to the terms hereof, solvpath will provide Subscriber with reasonable technical support services
            in accordance with the terms set forth in <strong>Exhibit B.</strong>
          </p>
          <h5>
            <strong>2. Right to Use.</strong>
          </h5>
          <p>
            2.1 <span>Limited License.</span> Subject to and in accordance with the terms and conditions of this
            Agreement and payment of all Fees, solvpath hereby grants Subscriber a limited, non-exclusive,
            non-transferable, non-delegable and non-assignable license to use the Software and Services.
          </p>
          <p>
            2.2 <span>Subscriber Responsibilities.</span> Customer (i) will keep its passwords secure and confidential
            and use industry-standard password management practices; (ii) is primarily responsible for Customer Data and
            all activity in its Services accounts; (iii) will use commercially reasonable efforts to prevent
            unauthorized access to its account and notify solvpath promptly of any such unauthorized access; and (iv)
            may use the Services only in accordance with the Services’ technical documentation and applicable law.
          </p>
          <br />
          <h5>
            <strong>3. Restrictions and Prohibitions on Use.</strong>
          </h5>
          <p>
            Your license for access and use of the Site and Services and any information, materials or Documentation
            (collectively defined as <strong>“Content and Materials”</strong>) therein are subject to the following
            restrictions and prohibitions on use:
          </p>
          <p>You may not:</p>
          <p>
            3.1 Copy, print (except as expressly permitted by this Agreement), republish, display, distribute, transmit,
            sell, rent, lease, loan or otherwise make available in any form or by any means any portion of the Site or
            Services or any Content or Material retrieved therefrom;
          </p>
          <p>
            3.2 Use the Site or Services or any materials obtained from the Site or Services to develop, of as a
            component of, any information, storage or retrieval system, database, information base, or similar source
            (in any media now existing or hereafter developed), that is offered for commercial distribution of any kind,
            including through sale, license, lease, rental, subscription, or any other commercial distribution
            mechanism;
          </p>
          <p>3.3 Create compilations of derivative works of any Content and Materials from the Site or Services;</p>
          <p>
            3.4 Use any Content and Materials from the Site or Services in any manner that may infringe any copyright,
            intellectual property right, proprietary right, or proprietary right of solvpath or any third parties.
          </p>
          <p>
            3.5 Remove, change or obscure any copyright notice or other proprietary notice or terms of use contained in
            the Site or Services;
          </p>
          <p>
            3.6 Make any portion of the Site or Services available through any timesharing system, service bureau, the
            Internet or any other technology now existing or developed in the future;
          </p>
          <p>
            3.7 Remove, decompile, disassemble or reverse engineer any Site or Services software or use any network
            monitoring or discovery software to determine the Site or Services plan architecture;
          </p>
          <p>3.8 Use any automatic or manual process to harvest information from the Site or Services;</p>
          <p>
            3.9 Use the Site or Services for the purpose of gathering information for or transmitting (1) unsolicited
            commercial email in violation of the CAN-SPAM Act of 2003; (2) email that makes use of headers, invalid or
            nonexistent domain names or other means of deceptive addressing; and (3) unsolicited telephone calls, SMS or
            text messages, or facsimile transmissions in violation of the Telephone Consumer Protection Act of 1991 and
            any rule changes or amendments thereto;
          </p>
          <p>
            3.10 Use the Site or Services in a manner that violates any international, federal, state, or local laws,
            rules, and regulations, including, without limitation, laws regulating email, text messaging, facsimile
            transmissions or telephone solicitations;
          </p>
          <p>3.11 Use a single account for multiple business entities in an unauthorized manner; and</p>
          <p>
            3.12 Export or re-export the Site or Services or any portion thereof, or any software available on or
            through the Site or Services, in violation of the export control laws or regulations of the United States.
          </p>
          <h5>
            <strong>4. Payment of Fees.</strong>
          </h5>
          <p>
            4.1 <span>Fees.</span> Fees shall be payable by Subscriber to solvpath pursuant to <br />
            <strong>Exhibit C</strong> hereto, which is incorporated into the Agreement by reference.
          </p>
          <p>
            4.2 <span>Payment.</span> Subscriber agrees to pay solvpath all applicable charges as defined in the
            attached <strong>Exhibit C</strong> to its accounts for services provided, in United States dollars, in
            accordance with the payment policy in effect at the time those charges become due. Subscriber will complete
            a credit card authorization form and/or a digital payment authorization, which will authorize solvpath to
            charge Subscriber’s credit card on file on a monthly basis for the subscription and usage fees set forth in
            Exhibit C, as agreed to by Subscriber. Subscriber agrees to not charge back any of the related fees
            associated with this account, and any charge backs will result in Subscriber paying associated charge back
            fees. Additionally, if Subscriber provides solvpath with a credit card, charge card that expires during the
            term of this Agreement, solvpath reserves the right to charge any renewal card issued to Subscriber as a
            replacement without any additional Subscriber consent.
          </p>
          <p>
            4.3 <span>Billing.</span> solvpath will bill its subscription fees (as set forth in Exhibit C) on the first
            of each month, at which time solvpath will charge Subscriber’s credit card on file. Any usage fees (as set
            forth in Exhibit C) will be billed on a monthly basis, reflecting the previous month’s usage. For the
            avoidance of doubt, and for purposes of illustration only, on the first of January, Subscriber will be
            charged the agreed-upon subscription fee. The usage fees for January, however, will be billed the following
            month, in February, along with February’s subscription fees.
          </p>
          <p>
            4.4 <span>No Refunds.</span> All payments made to solvpath are NON-REFUNDABLE, including, without
            limitation, any setup fees, work order fees, monthly subscription and usage fees, and per session/ticket
            fees.
          </p>
          <p>
            4.5 <span>Failure to Make Payment.</span> Fees shall be due as set forth in Exhibit c. In the event of any
            failure by Subscriber to make payment, or upon any Subscriber charge-back, Subscriber will be responsible
            for all reasonable expenses (including reasonable attorneys’ fees) incurred by solvpath in collecting such
            amounts plus interest at the rate of the lesser of one and one-half percent (1.5%) per month or the highest
            rate permissible under applicable law for the actual number of days elapsed. Subscriber shall also be
            subject to the termination provisions contained in Section 5 below.
          </p>
          <p>
            4.6 <span>Other Charges.</span> Subscriber is solely responsible for any phone or SIP minutes, phone numbers
            and SMS/MMS charges, including any post-termination fees as set forth in Section 5.6 below.
          </p>
          <h5>
            <strong>5. Term and Termination.</strong>
          </h5>
          <p>
            5.1 <span>Term.</span> This Agreement is legally binding as of the Effective Date and shall continue until
            terminated as provided for herein. Unless this Agreement is terminated in accordance with the terms set
            forth herein, the Initial Term of this Agreement shall be ninety (90) days. Following the Initial Term and
            unless otherwise terminated as provided for this Agreement, this Agreement shall automatically renew for
            successive one (1) year terms (each, a “Renewal Term”) until such time as a party provides the other party
            with written notice of termination pursuant to the provisions below.
          </p>
          <p>
            5.2 <span>Termination for Cause.</span> You agree that solvpath may, under certain circumstances and without
            prior notice, immediately terminate your solvpath account and access to the Services. Cause for such
            termination may include, without limitation, (a) breaches or violations of this Agreement or other
            incorporated agreements or guidelines, (b) requests by law enforcement or other government agencies, (c) a
            request by you (self-initiated account deletions), (d) discontinuance or material modification to the
            Service (or any part thereof), (e) unexpected technical issues or problems, and (f) extended periods of
            inactivity, and (g) any reason whatsoever. Termination of your solvpath account includes (a) removal of
            access to all offerings within the Service, (b) deletion of your password and all related information, files
            and content associated with or inside your account (or any part thereof), and (c) barring further use of the
            Services. Further, you agree that all terminations for cause shall be made in solvpath’s sole discretion and
            that solvpath shall not be liable to you or any third-party for any termination of your account, deletion of
            data, or access to the Services.
          </p>
          <p>
            5.3 <span>Termination for Convenience.</span> In addition to its right to terminate for cause per section
            5.2 above, solvpath has the right, in its sole discretion, to terminate this Agreement at any time for its
            convenience subject to fifteen (15) days advanced written notice.
          </p>
          <p>
            5.4 <span>Subscriber’s Right to Terminate. </span> You may stop using the Services at any time by closing
            your Account or ceasing to use the Services. In such event, you will not be entitled to any refund of any
            fees that you have paid prior to you ceasing to use the Service, including any monthly subscription fees,
            and any usage for the month is still due and payable to solvpath.
          </p>
          <p>
            5.5 <span> Payments upon Termination.</span> Upon termination of this Agreement for any reason, Subscriber
            shall pay to solvpath all amounts due and payable hereunder, if any.
          </p>
          <p>
            5.6 <span>Post-Termination Fees and Costs.</span> To the extent Subscriber opts to port its assigned local
            or toll free phone number(s) to solvpath during the course of this Agreement, Subscriber is solely
            responsible and liable for paying any fees or costs associated with voice/usage minutes incurred
            post-termination. To avoid further liability to solvpath for any such fees or costs, Subscriber is solely
            responsible for directing solvpath to transfer its assigned local or toll free phone numbers back to the
            Subscriber upon termination of this Agreement.
          </p>
          <h5>
            <strong>6. Mutual Representations and Warranties.</strong>
          </h5>
          <p>
            EACH PARTY REPRESENTS AND WARRANTS TO THE OTHER PARTY THAT: (A) IT HAS THE FULL CORPORATE RIGHT, POWER AND
            AUTHORITY TO ENTER INTO THE AGREEMENT, TO GRANT THE LICENSES GRANTED HEREUNDER AND TO PERFORM THE ACTS
            REQUIRED OF IT HEREUNDER; (B) THE EXECUTION OF THE AGREEMENT BY IT AND THE PERFORMANCE OF ITS OBLIGATIONS
            AND DUTIES HEREUNDER, DOES NOT AND WILL NOT VIOLATE ANY AGREEMENT TO WHICH IT IS A PARTY OR BY WHICH IT IS
            OTHERWISE BOUND; (C) WHEN EXECUTED AND DELIVERED, THE AGREEMENT WILL CONSTITUTE THE LEGAL, VALID AND BINDING
            OBLIGATION OF EACH PARTY, ENFORCEABLE AGAINST EACH PARTY IN ACCORDANCE WITH ITS TERMS; (D) THE INDIVIDUAL
            COMPLETING THE AGREEMENT HAS THE AUTHORITY TO LEGALLY BIND THE PARTY.
          </p>
          <h5>
            <strong>7. Subscriber’s Representations and Warranties.</strong>
          </h5>
          <p>
            In addition to the mutual representations in Section 6 above, You represent, warrant, and understand that:
            (a) you will perform your rights, duties and obligations under this Agreement, and, at all times, you will
            be in compliance with all applicable local, state, and federal laws, rules and regulations; (b) the Software
            technology provides a self-service platform, and you will be solely responsible for adjusting your
            customized account settings correctly and for monitoring the Software regularly to ensure, among other
            things, timely response to customer inquiries and enhanced customer service experience; (c) your collection
            and use of your customer’s personally-identifiable information or technical data shall be in compliance with
            all applicable local, state, and federal laws, rules and regulations, pertaining in any way to the privacy,
            confidentiality, security, management, disclosure, reporting, and any other obligations related to the
            possession or use of any of your customer’s personally identifiable information or technical data; including
            but not limited to the Controlling the Assault of Non-Solicited Pornography and Marketing Act (“CAN-SPAM”)
            and the Telephone Consumer Protection Act (“TCPA”) (collectively “Privacy Laws and Regulations”); (d) to the
            extent applicable, you will comply with all European Union data privacy laws, including without limitation,
            the General Data Protection Regulation (GDPR); (e) you are not currently aware of or subject to any
            investigation or litigation, either by a government regulator or by a third party; (d) the information you
            provided to solvpath is truthful, accurate, complete and not misleading in any material respect.
          </p>
          <p>
            Failure to comply with these Representations and Warranties may result in the immediate suspension and/or
            termination of your account.
          </p>
          <h5>
            <strong>8. Warranty and Disclaimer.</strong>
          </h5>
          <p>
            solvpath shall use commercially reasonable efforts consistent with prevailing industry standards to maintain
            the Services in a manner that minimizes errors and interruptions in the Services and shall perform the
            implementation of Services in a professional and workmanlike manner. Services may be temporarily unavailable
            for scheduled maintenance or for unscheduled emergency maintenance, either by solvpath or by third-party
            providers, or because of other causes beyond solvpath’s reasonable control, but solvpath shall use
            commercially-reasonable efforts to provide advance notice in writing or by e-mail of any scheduled service
            disruption. However, solvpath does not warrant that the Services will be uninterrupted or error-free; nor
            does it make any warranty as to the results that may be obtained from the use of the Services. EXCEPT AS
            EXPRESSLY SET FORTH IN THIS SECTION, THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE” BASIS, AND YOU
            EXPRESSLY AGREE THAT YOUR USE OF THE SERVICES IS AT YOUR OWN RISK. SOLVPATH DISCLAIMS ALL WARRANTIES,
            EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
            PARTICULAR PURPOSE AND NON-INFRINGEMENT. SOLVPATH FURTHER DISCLAIMS ANY WARRANTY REGARDING THE AVAILABILITY,
            ACCURACY OR CONTENT OF THE SITE AND/OR SERVICES AND/OR INFORMATION, PRODUCTS OR SERVICES AVAILABLE THROUGH
            THE SERVICES OR ANY ECONOMIC BENEFIT YOU MAY GAIN FROM USE OF THE SITE AND/OR SERVICES. SOLVPATH MAKES NO
            GUARANTEES, REPRESENTATIONS OR WARRANTIES AS TO THE LEVEL OF CUSTOMER SERVICE EXPERIENCE SUBSCRIBER CAN
            EXPECT FROM MANAGING ITS SELF-SERVICE PLATFORM BY ENTERING INTO THIS AGREEMENT AND SUBSCRIBING TO THE SITE
            AND/OR SERVICES.
          </p>
          <h5>
            <strong>9. Subscriber Data.</strong>
          </h5>
          <p>
            9.1 <span>Ownership.</span> Subscriber’s data (“Subscriber Data”) which shall also be known and treated by
            Service Provider as Confidential Information) shall include: (a) Subscriber’s data collected, used,
            processed, stored, or generated as the result of the use of the Services; and, (b) personally identifiable
            information (“PII“) collected, used, processed, stored, or generated as the result of the use of the
            Services, including, without limitation, any information that identifies an individual, such as an
            individual’s social security number or other government-issued identification number, date of birth,
            address, telephone number, biometric data, mother’s maiden name, email address, credit card information, or
            an individual’s name in combination with any other of the elements listed herein. Subscriber Data is and
            shall remain the sole and exclusive property of Subscriber and all right, title, and interest in the same
            are reserved by Subscriber. This Section shall survive the termination of this Agreement.
          </p>
          <p>
            9.2 <span>solvpath’s Use of Subscriber Data.</span> solvpath is provided a limited license to Subscriber
            Data for the sole and exclusive purpose of providing the Services, including a license to collect, process,
            store, generate, and display Subscriber Data only to the extent necessary in the providing of the Services.
            Service Provider shall: (a) keep and maintain Subscriber Data in strict confidence, using such degree of
            care as is appropriate and consistent with its obligations as further described in this Agreement and
            applicable law to avoid unauthorized access, use, disclosure, or loss; (b) use and disclose Subscriber Data
            solely and exclusively for the purpose of providing the Services, such use and disclosure being in
            accordance with this Agreement and applicable law; and, (c) not use, sell, rent, transfer, distribute, or
            otherwise disclose or make available Subscriber Data for solvpath’s own purposes or for the benefit of
            anyone other than Subscriber without Subscriber’s prior written consent. This Section shall survive the
            termination of this Agreement.
          </p>
          <p>
            9.3 <span>Loss of Data.</span> In the event of any act, error or omission, negligence, misconduct, or breach
            that compromises or is suspected to compromise the security, confidentiality, or integrity of Subscriber
            Data or the physical, technical, administrative, or organizational safeguards put in place by solvpath that
            relate to the protection of the security, confidentiality, or integrity of Subscriber Data, solvpath shall,
            as applicable: (a) notify Subscriber as soon as practicable but hours of becoming aware of such occurrence;
            (b) cooperate with Subscriber in investigating the occurrence, including making available all relevant
            records, logs, files, data reporting, and other materials required to comply with applicable law or as
            otherwise required by Subscriber; (c) in the case of PII, at Subscriber’s sole election, notify the affected
            individuals who comprise the PII as soon as practicable but no later than is required to comply with
            applicable law, or, in the absence of any legally required notification period, within five (5) calendar
            days of the occurrence.
          </p>
          <p>
            9.4 <span>Third-Party Services.</span> solvpath’s Services inter-operate with third-party services
            (“Third-Party Services”) and depend on continuing availability of and access to the Third-Party Services,
            including application programming interfaces. If for any reason a Third-Party Service ceases to be available
            for solvpath’s Services on reasonable terms, solvpath may be unable to continue to provide all of the
            functions of its Services. If a Third-Party Service for which Subscriber has purchased solvpath’s Services
            becomes permanently unavailable for inter-operation with solvpath’s Services (other than as a result of an
            act or omission of Subscriber), Subscriber is nevertheless responsible for any fees due to solvpath under
            the terms of this Agreement. To the extent the issue with the Third-Party Services is not resolved in a
            timely manner, then Subscriber may terminate the Services per the terms set forth in Section 5 of this
            Agreement.
          </p>
          <h5>
            <strong>10. Confidentiality; Proprietary Rights.</strong>
          </h5>
          <p>
            10.1 Each Party (the “Receiving Party”) understands that the other party (the “Disclosing Party”) has
            disclosed or may disclose business, technical or financial information relating to the Disclosing Party’s
            business (hereinafter referred to as “Proprietary Information” of the Disclosing Party). Proprietary
            Information of solvpath includes non-public information regarding features, functionality and performance of
            the Site and/or Services. Proprietary Information of Subscriber includes non-public data provided by
            Subscriber to solvpath to enable the provision of the Services (“solvpath Data”).
          </p>
          <p>
            10.2 The Receiving Party agrees: (i) to take reasonable precautions to protect such Proprietary Information,
            and (ii) not to use (except in performance of the Services or as otherwise permitted herein) or divulge to
            any third person any such Proprietary Information. The Disclosing Party agrees that the foregoing shall not
            apply with respect to any information after five (5) years following the disclosure thereof, or five (5)
            years from the date of termination of this Agreement (whichever is later), or any information that the
            Receiving Party can document (a) is or becomes generally available to the public, or (b) was in its
            possession or known by it prior to receipt from the Disclosing Party, or (c) was rightfully disclosed to it
            without restriction by a third party, or (d) was independently developed without use of any Proprietary
            Information of the Disclosing Party or (e) is required to be disclosed by law.
          </p>
          <p>
            10.3 Additionally, the Receiving Party shall ensure that Confidential Information is disclosed to its
            employee(s), authorized agent(s) and/or independent contractor(s) on a need to know basis and that all such
            parties have agreed in writing to be bound by these confidentiality obligations and to use reasonable care,
            but not less care than they use with respect to their own information of like character.
          </p>
          <p>
            10.4 In the event that the Receiving Party or any of such party’s agents become legally compelled (by
            deposition, interrogatory, request for documents, subpoena, civil investigative demand, valid court order,
            governmental demand or similar process) to disclose any of the Confidential Information of the Disclosing
            Party, the Receiving Party or person under the legal compulsion (the “Compelled Party”) from whom such
            information is being sought shall, unless prohibited by law, provide the Disclosing Party with prompt prior
            written notice of such requirement, allowing the Disclosing Party to take all necessary steps to move to
            quash or otherwise seek protection of said information from public disclosure. Both Parties will stipulate
            to any orders necessary to protect said information from public disclosure. In the event that such
            protective order or other remedy is not obtained, or the other party waives compliance with the provisions
            hereof, the Compelled Party agrees to furnish only such portion of the Confidential Information that the
            Compelled Party is advised by written opinion of its counsel is legally required to be furnished by it and
            shall exercise its reasonable best efforts to obtain reliable assurance that confidential treatment shall be
            accorded such Confidential Information.
          </p>
          <p>
            10.5 Violation of this section shall be considered a material breach of the Agreement and will entitle
            non-breaching Party to terminate this Agreement without liability to the other Party, and to pursue any and
            all other remedies available at law or in equity. The breaching Party acknowledges that a breach of this
            section would cause non-breaching Party irreparable harm and that non-breaching Party shall be entitled to
            appropriate injunctive relief in the event such breach is threatened or occurs. The non-breaching Party may
            seek injunctive relief without the necessity of filing a bond or undertaking and may seek injunctive relief
            without proof of damages. Notwithstanding the foregoing, solvpath’s total cumulative liability under this
            Section will not exceed an amount equal to all amounts actually received by solvpath from Subscriber during
            the twelve (12) month period immediately preceding any allegation by Subscriber of disclosure of Propri
          </p>
          <p>
            10.6 Upon termination of the Agreement, upon written demand by the Disclosing Party, the Receiving Party
            agrees that it will promptly return the Proprietary Information to the Disclosing Party, including all
            copies thereof or, if requested to do so by the Disclosing Party, will destroy the Propriety Information.
          </p>
          <h5>
            <strong>11. Dispute Resolution; Choice of Law.</strong>
          </h5>
          <p>
            The validity, interpretation, construction and performance of this Agreement shall be governed by and
            construed in accordance with the laws of the State of New York, without giving effect to any choice of law
            or conflict of law rules or provisions. The Parties agree that any suit or proceeding arising out of or
            relating to this Agreement shall be commenced in a federal or state court in the State of New York, and each
            party irrevocably submits to the jurisdiction and venue of such courts.{' '}
            <span>
              <strong>
                Should a dispute arise concerning the terms and conditions of the Agreement or the breach of same by any
                Party hereto, solvpath expressly reserves the option to require Subscriber to first submit the dispute
                for resolution by binding arbitration before the American Arbitration Association in the State of New
                York in accordance with the then current Commercial Arbitration Rules.
              </strong>
            </span>{' '}
            Any award rendered shall be final and conclusive, and a judgment thereon may be entered in any court of
            competent jurisdiction. Nothing contained herein
            <br /> shall be construed to preclude either Party from seeking injunctive relief in order to protect its
            rights pending an outcome in arbitration. Nothing contained herein shall be construed to limit any legal
            remedies available to solvpath. solvpath may, in its sole discretion, elect to file an action in any court
            of competent jurisdiction in the State of New York, in lieu of and despite the alternative dispute
            resolution provision above. Unless expressly stated herein to the contrary, nothing in this Agreement,
            whether express or implied, is intended to confer any rights or remedies under or by reason of this
            Agreement on any persons other than the Parties hereto and their respective legal representatives,
            successors and permitted assigns. Nothing in this Agreement is intended to relieve or discharge the
            obligation or liability of any third-persons to any party to this Agreement, nor shall any provision give
            any third persons any right of subrogation or action over or against any party to this Agreement.
          </p>
          <h5>
            <strong>12. Limitation of Liability.</strong>
          </h5>
          <p>
            NOTWITHSTANDING ANYTHING TO THE CONTRARY, SOLVPATH AND ITS SUPPLIERS (INCLUDING BUT NOT LIMITED TO ALL
            EQUIPMENT AND TECHNOLOGY SUPPLIERS), OFFICERS, AFFILIATES, REPRESENTATIVES, CONTRACTORS AND EMPLOYEES SHALL
            NOT BE RESPONSIBLE OR LIABLE WITH RESPECT TO ANY SUBJECT MATTER OF THIS AGREEMENT OR TERMS AND CONDITIONS
            RELATED THERETO UNDER ANY
          </p>
          <p>
            CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER THEORY: (A) FOR ERROR OR INTERRUPTION OF USE OR FOR LOSS OR
            INACCURACY OR CORRUPTION OF DATA OR COST OF PROCUREMENT OF SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY OR LOSS
            OF BUSINESS; (B) FOR ANY INDIRECT, EXEMPLARY, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES; (C) FOR ANY
            MATTER BEYOND
          </p>
          <p>
            SOLVPATH’S REASONABLE CONTROL; OR (D) FOR ANY AMOUNTS THAT, TOGETHER WITH AMOUNTS ASSOCIATED WITH ALL OTHER
            CLAIMS, EXCEED THE FEES PAID BY SUBSCRIBER TO SOLVPATH FOR THE SERVICES UNDER THIS AGREEMENT IN THE 12
            MONTHS PRIOR TO THE ACT THAT GAVE RISE TO THE LIABILITY, IN EACH CASE, WHETHER OR NOT SOLVPATH HAS BEEN
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </p>
          <h5>
            <strong>13. Indemnification.</strong>
          </h5>
          <p>
            13.1 Subscriber agrees to indemnify, defend and hold solvpath and our partners, agents, officers, directors,
            employees, subcontractors, successors, assigns, third party suppliers of information and documents,
            attorneys, advertisers, product and service providers, and affiliates (collectively, “Affiliated Parties”)
            harmless from any liability, loss, claim and expense, (including reasonable attorneys’ fees and expenses)
            (collectively “CLAIMS”) arising from (a) any alleged or actual breach of the terms of this Agreement; (b)
            any alleged or actual violation of an applicable law; or (c) the content of any Subscriber data.
          </p>
          <p>
            13.2 Subscriber agrees: (i) to promptly notify solvpath in writing of any Claim that it becomes aware of and
            provide solvpath with the opportunity to defend or negotiate a settlement of any such Claim at Subscriber’s
            expense; and (ii) to cooperate fully with solvpath, at Subscriber’s expense, in defending or settling such
            Claim.
          </p>
          <p>
            13.3 solvpath has the right, but not the obligation to, assume the exclusive defense and control of any
            matter subject to indemnification, for which Subscriber shall pay for all reasonable costs associated with
            such defense, including, without limitation, reasonable attorneys’ fees, expert fees, costs and settlement.
          </p>
          <p>13.4 No settlement may be consummated without solvpath’s express written authorization.</p>
          <h5>
            <strong>14. Nontransferable</strong>
          </h5>
          <p>
            Your right to use the Site and Services is not transferable or assignable. Any username, password or right
            given to you to obtain information or documents is not transferable or assignable. Notwithstanding the
            foregoing, either party may assign the Agreement, and any of its rights hereunder, in connection with the
            sale of all or substantially all of its assets or stock sale, merger or other corporate reorganization
            resulting in a change of control, with the consent of the other party, which should not be unreasonably
            withheld.
          </p>
          <h5>
            <strong>15. Entire Agreement; Construction; Modification.</strong>
          </h5>
          <p>
            The Agreement represents the complete and entire expression of the agreement between the Parties, and shall
            supersede any and all other agreements, whether written or verbal, between the Parties. The Agreement shall
            be construed as if both Parties equally participated in its drafting, and thus shall not be construed
            against the drafter. Unless expressly set forth herein, the Agreement may be amended only by a written
            agreement executed by an authorized representative of each Party. solvpath may, from time to time, issue
            digital notices with new amended terms, as reflected in the Parties’ continued relationship. The issuance of
            such notices will constitute solvpath’s authorized agreement to such terms, and Subscriber’s digital
            signature to such notices will similarly constitute Subscriber’s authorized agreement to such terms.
          </p>
          <h5>
            <strong>16. Non-Waiver; Severability..</strong>
          </h5>
          <p>
            No waiver of any breach of any provision of the Agreement shall constitute a waiver of any prior,
            concurrent, or subsequent breach of the same or any other provisions hereof, and no waiver shall be
            effective unless made in writing and signed by an authorized representative of the waiving Party. If any
            provision contained in the Agreement is determined to be invalid, illegal, or unenforceable in any respect
            under any applicable law, then such provision will be severed and replaced with a new provision that most
            closely reflects the real intention of the Parties, and the remaining provisions of the Agreement will
            remain in full force and effect.
          </p>
          <h5>
            <strong>17. Status of the Parties.</strong>
          </h5>
          <p>
            The Parties hereto are independent contractors. There is no relationship of partnership, agency, employment,
            franchise or joint venture between the Parties. Neither Party has the authority to bind the other or incur
            any obligation on its behalf
          </p>
          <h5>
            <strong>18. Force Majeure.</strong>
          </h5>
          <p>
            With the exception of Subscriber’s contractual payment of Fees obligation, neither party shall be liable for
            delays or failure to perform the Services or the Agreement due to causes beyond its reasonable control. Such
            delays include, but are not limited to, fire, explosion, flood or other natural catastrophe, governmental
            legislation, acts, orders or regulations, strikes or labor difficulties, to the extent not occasioned by the
            fault or negligence of the delayed party. Any such excuse for delay shall last only as long as the event
            remains beyond the reasonable control of the delayed party. However, the Party whose performance is so
            delayed shall use commercially reasonable, good-faith efforts to minimize the effects of such delay and
            shall resume performance as soon as practicable.
          </p>
          <h5>
            <strong>19. Export Compliance.</strong>
          </h5>
          <p>
            You acknowledge that the Software is subject to United States export control and economic sanctions laws,
            regulations, and requirements, and to import laws, regulations, and requirements of foreign governments. You
            agree that (1) all use, exports, and imports related to this Agreement will be in compliance with these laws
            and regulations and (2) you shall not allow any third party to export, re-export, or transfer any part of
            Software in violation of these laws and regulations. The foregoing obligations include but are not limited
            to you or a third party exporting, transferring, or importing the Software to: (a) to any country subject to
            export control embargo or economic sanctions implemented by any agency of the U.S. or foreign governments;
            (b) any person or entity on any of the U.S. Government’s Lists of Parties of Concern
            (https://www.bis.doc.gov/index.php/policy-guidance/lists-of- parties-of-concern) or <br />
            applicable international specially-designated parties or economic sanctions programs; (c) to any end-user
            for any known end-use related to the proliferation of nuclear, chemical or biological weapons or missiles,
            without first obtaining any export license or other approval that may be required by any U.S. Government
            agency having jurisdiction with respect to the transaction; or (d) otherwise in violation of any export or
            import laws, regulations or requirements of any United States or foreign agency or authority.
          </p>
          <h5>
            <strong>20. Notices.</strong>
          </h5>
          <p>
            All notices or other communications related to this Agreement shall be in writing and shall be delivered by
            (a) personal service; (b) electronic mail (to the email address appearing in the signature blocks below); or
            (c) United States certified mail, return receipt requested, postage prepaid to the mailing addresses
            appearing in the signature blocks below. Notice given by personal service or via email shall be deemed
            effective on the date it is delivered to the addressee, and notice mailed shall be deemed effective five (5)
            days after the date of mailing.
          </p>
          <h5>
            <strong>21. Headings.</strong>
          </h5>
          <p>
            The inclusion of headings in this Agreement is for convenience of reference only and shall not affect the
            construction or interpretation hereof.
          </p>
          <h5>
            <strong>22. Counterparts.</strong>
          </h5>
          <p>
            This Agreement may be executed in separate counterparts, each of which is an original, but all of which
            shall be deemed to be a single instrument. This Agreement will be fully effective as of the date executed
            copies are exchanged between the parties. Counterparts may be executed either in original or faxed form, and
            the Parties adopt any signatures received by a receiving fax machine as original signatures of the parties.
          </p>
          <h4>
            <span className="d-flex justify-content-center mb-3">
              <strong>EXHIBIT A</strong>
            </span>
          </h4>
          <h5 className="d-flex justify-content-center mb-3">
            <strong>Service Level Terms</strong>
          </h5>
          <p>
            The Services shall be available 99.9%, measured monthly, excluding holidays and weekends and scheduled
            maintenance. If Subscriber requests maintenance during these hours, any uptime or downtime calculation will
            exclude periods affected by such maintenance. Further, any downtime resulting from outages of third party
            connections or utilities or other reasons beyond solvpath’s control will also be excluded from any such
            calculation.
          </p>
          <p>
            Subscriber’s sole and exclusive remedy, and solvpath’s entire liability, in connection with Services
            availability shall be that for each period of downtime exceeding one hour twice during the term of this
            Agreement, solvpath will credit Subscriber 5% of Service fees of the next monthly fee. If there is
            unscheduled monthly downtime exceeding one hour three times during the term of this Agreement, solvpath will
            credit Subscriber an additional 5% of the next monthly fee.
          </p>
          <p>
            Downtime shall begin to accrue as soon as Subscriber (with notice to solvpath) recognizes that downtime is
            taking place and continues until the availability of the Services is restored. In order to receive downtime
            credit, Subscriber must notify solvpath in writing within 24 hours from the time of downtime, and failure to
            provide such notice will forfeit the right to receive downtime credit. Such credits may not be redeemed for
            cash.
          </p>
          <p>
            solvpath’s blocking of data communications or other Service in accordance with its policies shall not be
            deemed to be a failure of solvpath to provide adequate service levels under this Agreement.
          </p>

          <h4>
            <span className="d-flex justify-content-center mb-3 mt-3">
              <strong>EXHIBIT B</strong>
            </span>
          </h4>
          <h5 className="d-flex justify-content-center mb-3">
            <strong>Support Terms</strong>
          </h5>
          <p>
            solvpath will provide Technical Support to Subscriber via both telephone and electronic mail on weekdays
            during the hours of 9:00 am through 5:00 pm Eastern Time, with the exclusion of Federal Holidays (“
            <strong>Support Hours</strong>”).
          </p>
          <p>
            Subscriber may initiate a helpdesk ticket during Support Hours by calling <strong>877-805-4520</strong> or
            any time by emailing <strong>support@solvpath.com.</strong>
          </p>
          <p>
            solvpath will use commercially reasonable efforts to respond to all Helpdesk tickets within one (1) business
            day.
          </p>
          <p>
            <strong>IN WITNESS WHEREOF</strong>, the parties have executed the Agreement on the date set forth on the
            first page hereof.
          </p>
          <p>
            <strong>solvpath LLC</strong>
          </p>
          <br />
          <p>Name: Carl DAgostino</p>
          <br />
          <p>Title: CEO</p>
          <br />
          <p>Address: 244 Pettit Ave</p>
          <br />
          <p>Bellmore, NY 11710</p>
          <br />
          <p>Country: United States of America</p>
          <br />
          <h4>
            <strong>EXHIBIT A</strong>
          </h4>
          <h5>
            <strong>Standard License Fees</strong>
          </h5>
          <p>
            solvpath Standard License Fees. In consideration for payment of the fees due and compliance with the
            Agreement, solvpath shall provide you with access to the solvpath Services, which include the features
            listed on the Site. Upon the parties’ acceptance of this Agreement, the initial license and setup fees are
            due and are nonrefundable.
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

interface PropTypes {
  showModal: boolean;
  handleClose: () => void;
}

export default PrivacyPolicyModal;
