import './../styles/Header.scss';
import { useEffect, useState } from 'react';
import logo from './../assets/solvpath-logo.png';
import { Menu } from 'iconoir-react';
import { useLocation, useNavigate } from 'react-router-dom';

const Header = ({
  homeRef,
  whoWeAreRef,
  featuresRef,
  serviceRef,
  analyticsRef,
  testimonialsRef,
  faqsRef,
  isMobile,
}: {
  homeRef: React.RefObject<HTMLElement>;
  whoWeAreRef: React.RefObject<HTMLElement>;
  featuresRef: React.RefObject<HTMLElement>;
  serviceRef: React.RefObject<HTMLElement>;
  analyticsRef: React.RefObject<HTMLElement>;
  testimonialsRef: React.RefObject<HTMLElement>;
  faqsRef: React.RefObject<HTMLElement>;
  isMobile: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const scrollToHome = () => {
    if (location.pathname !== '/') {
      navigate('/');
    }
    if (homeRef.current) {
      setIsMenuOpen(false);
      homeRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToWhoWeAre = () => {
    if (location.pathname !== '/') {
      navigate('/');
    }
    if (whoWeAreRef.current) {
      setIsMenuOpen(false);
      const offset = isMobile ? 0 : 150;
      const targetPosition = whoWeAreRef.current.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth',
      });
    }
  };

  const scrollToFeatures = () => {
    if (location.pathname !== '/') {
      navigate('/');
    }
    if (featuresRef.current) {
      setIsMenuOpen(false);
      const offset = isMobile ? 0 : 150;
      const targetPosition = featuresRef.current.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth',
      });
    }
  };

  const scrollToFaqs = () => {
    if (location.pathname !== '/') {
      navigate('/');
    }
    if (faqsRef.current) {
      setIsMenuOpen(false);
      const offset = isMobile ? 0 : 150;
      const targetPosition = faqsRef.current.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth',
      });
    }
  };

  const scrollToTestimonials = () => {
    if (location.pathname !== '/') {
      navigate('/');
    }
    if (testimonialsRef.current) {
      setIsMenuOpen(false);
      const offset = isMobile ? 0 : 150;
      const targetPosition = testimonialsRef.current.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth',
      });
    }
  };

  const handleBooking = () => {
    const currentPath = location.pathname;
    if (currentPath === '/') navigate('/booking');
    else navigate(`${currentPath}/booking`);
  };

  return (
    <div className={`header-view ${isScrolled ? 'header-fixed' : ''}`}>
      {isMobile ? (
        <>
          <div className="header">
            <img className="logo" src={logo} alt="logo" onClick={scrollToHome} />
            <div className="demo-container">
              <button onClick={handleBooking}>Book a Demo</button>
              <div className="menu-btn">
                {!isMenuOpen ? (
                  <Menu onClick={() => setIsMenuOpen(true)} />
                ) : (
                  <Menu onClick={() => setIsMenuOpen(false)} />
                )}
              </div>
            </div>
            {isMenuOpen && (
              <>
                <div className="menu">
                  <span onClick={scrollToWhoWeAre}>Who We Are</span>
                  <span onClick={scrollToFeatures}>Features</span>
                  <span onClick={scrollToTestimonials}>Testimonials</span>
                  <span onClick={scrollToFaqs}>FAQS</span>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <div className="header">
          <img className="logo" src={logo} alt="logo" onClick={scrollToHome} />
          <div className="header-links">
            <span onClick={scrollToWhoWeAre}>Who We Are</span>
            <span onClick={scrollToFeatures}>Features</span>
            <span onClick={scrollToTestimonials}>Testimonials</span>
            <span onClick={scrollToFaqs}>FAQS</span>
          </div>
          <button className="demo-button" onClick={handleBooking}>
            Request Demo
          </button>
        </div>
      )}
    </div>
  );
};

export default Header;
