import './../styles/Home.scss';

import { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { faqs, features, testimonials } from '../utils/constants';

import Slider from 'react-slick';
import homeBanner from './../assets/home-banner.png';
import homeBannerMobile from './../assets/home-banner-mobile.png';
import playIcon from './../assets/play-logo.png';
import shopify from './../assets/partners-with/shopify.svg';
import bigCommerce from './../assets/partners-with/big-commerce.svg';
import clickbank from './../assets/partners-with/click-bank.png';
import wooCommerce from './../assets/partners-with/woo-commerce.svg';
import checkoutchamp from './../assets/partners-with/checkout-champ.svg';
import stickyio from './../assets/partners-with/sticky-io.svg';
import vrio from './../assets/partners-with/vrio.svg';
import sendlane from './../assets/partners-with/sendlane.svg';
import gorglas from './../assets/partners-with/gorgias.svg';
import shipoffers from './../assets/partners-with/ship-offers.svg';
import fulfillment from './../assets/partners-with/fulfillment.svg';
import bold from './../assets/partners-with/bold.svg';
import customerService from '../assets/customer-service.png';
import reporting from '../assets/reporting.png';
import retention from '../assets/gifs/retention.gif';
import callUrl from '../assets/gifs/call-url.gif';
import helpDesk from '../assets/gifs/help-desk.gif';
import minusIcon from '../assets/minus.png';
import plusIcon from '../assets/plus.png';
import underline from '../assets/underline.png';
import customerPortal from '../assets/gifs/customer-portal.gif';
import DemoVideoModal from '../components/modals/DemoVideoModal';
import VideoPlayer from '../components/VideoPlayer';
import { Testimonial } from '../utils/models';

const Logos = [
  bigCommerce,
  shopify,
  clickbank,
  wooCommerce,
  checkoutchamp,
  stickyio,
  vrio,
  sendlane,
  gorglas,
  shipoffers,
  fulfillment,
  bold,
];

const Home = ({
  homeRef,
  whoWeAreRef,
  featuresRef,
  serviceRef,
  analyticsRef,
  testimonialsRef,
  faqsRef,
  isMobile,
}: {
  homeRef: React.RefObject<HTMLDivElement>;
  whoWeAreRef: React.RefObject<HTMLDivElement>;
  featuresRef: React.RefObject<HTMLDivElement>;
  serviceRef: React.RefObject<HTMLDivElement>;
  analyticsRef: React.RefObject<HTMLDivElement>;
  testimonialsRef: React.RefObject<HTMLDivElement>;
  faqsRef: React.RefObject<HTMLDivElement>;
  isMobile: boolean;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [activeFeature, setActiveFeature] = useState<number | null>(
    features[0].id
  );
  const [testimonialList, setTestimonialList] =
    useState<Testimonial[]>(testimonials);
  const [activeAccordionItem, setActiveAccordionItem] = useState('0');
  const [activeTab, setActiveTab] = useState(features[0]?.id || null);
  const navigate = useNavigate();
  const location = useLocation();
  const [logos, setLogos] = useState<string[]>();

  useEffect(() => {
    if (location.pathname.includes('/kk')) {
      // Remove 'vrio' and 'stickyio' from the array
      const filteredLogos = [...Logos];
      filteredLogos.splice(5, 2); // Remove 2 elements starting from index 5
      setLogos(filteredLogos);
    } else setLogos(Logos);
  }, [location.pathname]);

  const toggleActiveTab = (featureId: number) => {
    setActiveTab((prevActiveTab) =>
      prevActiveTab === featureId ? null : featureId
    );
  };

  const handleAccordionToggle = (eventKey: any) => {
    setActiveAccordionItem((prevActiveAccordionItem) =>
      prevActiveAccordionItem !== eventKey ? eventKey : null
    );
  };

  const videoSettings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    dots: true,
    autoplay: true,
    autoplaySpeed: 10000,
  };

  const scrollToWhoWeAre = () => {
    if (whoWeAreRef.current) {
      let offset;
      if (window.location.pathname.includes('who-we-are'))
        offset = isMobile ? 30 : -50;
      else offset = isMobile ? 0 : -100;
      const targetPosition =
        whoWeAreRef.current.getBoundingClientRect().top +
        window.scrollY -
        offset;
      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (window.location.pathname.includes('who-we-are')) {
      setTimeout(() => {
        scrollToWhoWeAre();
      }, 0);
    }
  });

  const handleFeatureClick = (featureId: number) => {
    setActiveFeature(featureId);
  };

  const renderFeature = (feature: Feature) => (
    <div
      key={feature.id}
      className="work-details"
      style={{
        border: `${
          feature.id === activeFeature
            ? '2px solid #2EB3C9'
            : '2px solid #CCCCCC'
        }`,
        backgroundColor: `${
          feature.id === activeFeature ? '#2EB3C90D' : '#FFFFFF'
        }`,
      }}
      onClick={() => handleFeatureClick(feature.id)}
    >
      <div>
        <h6 className="work-detail-heading">{feature.heading}</h6>
        <p className="work-detail-paragraph">{feature.paragraph}</p>
      </div>
      {feature.id === activeFeature && (
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <div className="work-detail-tab"></div>
        </div>
      )}
    </div>
  );

  const handleWatchDemoClick = () => {
    setShowModal((prevShowModal) => !prevShowModal);
  };

  const updateTestimonials = (id: string, isPlaying: boolean) => {
    setTestimonialList((prevTestimonials) => {
      return prevTestimonials.map((testimonial) => ({
        ...testimonial,
        isPlaying: testimonial.id === id ? isPlaying : false,
      }));
    });
  };

  const renderTestimonials = () => {
    return testimonialList.map((testimonial) => (
      <VideoPlayer
        videoData={testimonial}
        key={testimonial.id}
        isMobile={isMobile}
        updateTestimonials={updateTestimonials}
      />
    ));
  };

  const renderFAQs = () => {
    return faqs.map((faq, index) => (
      <Accordion.Item
        key={index}
        eventKey={index.toString()}
        className={
          activeAccordionItem && activeAccordionItem.includes(index.toString())
            ? 'active'
            : ''
        }
        style={{
          padding:
            activeAccordionItem &&
            activeAccordionItem.includes(index.toString())
              ? '1rem 0 0 0'
              : '1rem 0',
        }}
      >
        <Accordion.Header>{faq.question}</Accordion.Header>
        <Accordion.Body>{faq.answer}</Accordion.Body>
      </Accordion.Item>
    ));
  };

  useEffect(() => {
    if (!logos) return;
    const container = document.querySelector('.logos');
    const slide = document.querySelector('.logos-slide');

    if (container && slide) {
      const cloneSlide = slide.cloneNode(true);
      container.appendChild(cloneSlide);
    }
  }, [logos]);

  const handleBooking = () => {
    const currentPath = location.pathname;
    if (currentPath === '/') navigate('/booking');
    else navigate(`${currentPath}/booking`);
  };

  return (
    <>
      {showModal && <DemoVideoModal />}
      <div className="home-view" ref={homeRef}>
        <div className="overview" data-aos="fade-up">
          <div className="tag">Powered by AI</div>
          <div className="heading">
            Automate your {!isMobile && <br />} Customer Support
            <img src={underline} alt="underline" />
          </div>
          <span className="description">
            solvpath provides a suite of products designed to automate customer
            support seamlessly. Take command of your post-sale strategy,
            improving retention, deflecting cancellations and returns, and
            significantly reducing refunds to enhance your bottom line. Stay
            ahead by unlocking an era of efficient, data-driven customer
            support.
          </span>
          <div className="get-started">
            <button className="learn-more-btn" onClick={handleBooking}>
              Schedule a Demo
            </button>
            <div
              className="watch-demo"
              onClick={handleWatchDemoClick}
              title="Watch Watch"
            >
              <img className="play-icon" src={playIcon} alt="play-icon"></img>
              <span>Watch Video</span>
            </div>
          </div>
        </div>
        <div className="home-banner">
          <img
            src={isMobile ? homeBannerMobile : homeBanner}
            alt="home-banner"
          />
        </div>
      </div>

      {/* Our Partners */}
      <div className="partners-view" data-aos="fade-up">
        <strong className="title">We’re Partnered with the best</strong>
        <div className="logos">
          <div className="logos-slide">
            {logos &&
              logos.map((logo, index) => (
                <img key={index} src={logo} alt={`logo-${index}`} />
              ))}
          </div>
        </div>
      </div>

      <div className="home-container">
        {/* Who We Are */}
        <div className="info-view" ref={whoWeAreRef} data-aos="fade-up">
          <div className="tag">Who We Are</div>
          <span className="paragraph">
            At solvpath, we're not just pioneers; we're revolutionizing customer
            support. Picture this: a suite of cutting-edge products that
            effortlessly automate customer support inquiries across various
            channels such as phone, email, social media, chat, and direct
            website visits.
          </span>
          <span className="paragraph" style={{ padding: '0' }}>
            Our AI-powered FAQ engine guarantees lightning-fast and accurate
            responses, while clever visual downsell flows supercharge retention
            and slash refunds. But here's the kicker: our user-friendly customer
            portal isn't just about a smooth user experience—it's a
            game-changer. It hands merchants the reins, giving them
            unprecedented control to boost their bottom line. solvpath isn't
            just a solution; it's a strategic move for merchants looking to
            skyrocket their profitability.
          </span>
        </div>

        {/* Why Choose Us */}
        <div className="why-choose-us" ref={featuresRef} data-aos="fade-up">
          <div className="tag">Features / Why Choose Us</div>

          <div className="feature-heading">
            Maximize Customer Retention <br />
            Optimize Customer Support
          </div>

          <div className="features-data">
            <div className="feature-row">
              <span className="feature-row-heading">
                <span className="feature-num">74.5</span>%
              </span>
              <p
                className="feature-row-detail"
                style={{ paddingRight: isMobile ? '1rem' : '' }}
              >
                Average <br /> self-service rate
              </p>
            </div>
            <div className="feature-row">
              <span className="feature-row-heading">
                <span className="feature-num">3</span>M +
              </span>
              <p className="feature-row-detail">
                Successful customer {!isMobile && <br />} support sessions
              </p>
            </div>
            <div className="feature-row">
              <span className="feature-row-heading">
                <span className="feature-num">5</span>% +
              </span>
              <p className="feature-row-detail">
                Average Increase in {!isMobile && <br />} customer retention
              </p>
            </div>
            <div className="feature-row">
              <span className="feature-row-heading">6% +</span>
              <p className="feature-row-detail">
                Average Reduction in {!isMobile && <br />}
                disputes and refunds
              </p>
            </div>
          </div>

          {/* How We Work */}
          {!isMobile ? (
            <div className="how-we-work">
              <img
                className="mobile-image"
                src={
                  activeFeature === 1
                    ? customerPortal
                    : activeFeature === 2
                    ? retention
                    : activeFeature === 3
                    ? helpDesk
                    : callUrl
                }
                alt="how-we-work"
              ></img>
              <div className="work-details-wrapper">
                {features.map(renderFeature)}
              </div>
            </div>
          ) : (
            <>
              {features.map((feature) => (
                <div
                  key={feature.id}
                  className="how-we-work-mobile"
                  style={{
                    height:
                      feature.id === 4 && activeTab !== feature.id
                        ? 'auto'
                        : activeTab !== feature.id
                        ? 'auto'
                        : '',
                    borderColor:
                      activeTab === feature.id ? '#2EB3C9' : '#CCCCCC',
                  }}
                >
                  <div className="work-details">
                    <div
                      className="work-heading-wrapper"
                      style={{ gap: feature.id === 4 ? '3rem' : '' }}
                    >
                      <h6 className="work-detail-heading">{feature.heading}</h6>
                      <img
                        src={activeTab === feature.id ? minusIcon : plusIcon}
                        alt="toggle-logo"
                        title="Open Tab"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleActiveTab(feature.id);
                        }}
                      />
                    </div>
                    <p className="work-detail-paragraph">{feature.paragraph}</p>
                    {activeTab !== feature.id && (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleActiveTab(feature.id);
                        }}
                        className="view-more-button"
                      >
                        View More &#129131;
                      </span>
                    )}
                    <img
                      className="mobile-image"
                      style={{
                        paddingBottom: activeTab === feature.id ? '10px' : '',
                      }}
                      src={
                        activeTab === feature.id
                          ? feature.gif
                          : feature.gifClosed
                      }
                      alt="how-we-work"
                    />
                  </div>
                </div>
              ))}
            </>
          )}
        </div>

        {/* Easy Customize Service */}
        <div className="customer-service" ref={serviceRef} data-aos="fade-up">
          <div className="service-details">
            <div className="title">
              <div className="tag">Channels We Support</div>
            </div>
            <strong className="section-heading">
              Omni-channel support experience that works
            </strong>
            <p className="service-paragraph">
              solvpath provides a complete omni-channel solution, allowing
              customers to access visual self-service support from any location.
              We seamlessly convert phone calls, social interactions, chat
              messages, website visits, and emails into a user-friendly digital
              support option. Moreover, our integrations with popular ticketing
              systems enhance efficiency, ensuring seamless communication and
              prompt closure of open tickets for effective deflection.
            </p>
          </div>
          <div className="customer-service-img">
            <img src={customerService} alt="customer-service"></img>
          </div>
        </div>

        {/* Analytics and Reporting */}
        <div
          className="analytics-and-reporting"
          ref={analyticsRef}
          data-aos="fade-up"
        >
          {!isMobile && (
            <div className="reporting-img">
              <img src={reporting} alt="reporting-img"></img>
            </div>
          )}
          <div className="reporting-details">
            <div className="title">
              <div className="tag">Data and Analytics</div>
            </div>
            <div className="section-heading">
              Dive Deep into Powerful Data and Analytics
            </div>
            <p className="reporting-paragraph">
              Uncover Frontline Customer Insights with solvpath: Navigate
              inquiry types, cancellation reasons, product issues, billing
              discrepancies, and more, empowering you to comprehend customer
              experiences and anticipate their next steps. This invaluable data
              not only enhances your campaign performance but also provides
              insights into customer needs, optimizing solvpath flows for
              increased efficiency and a stronger bottom line. In the realm
              where data is king, our dimensional style reporting enables a deep
              dive into over 100 data points for each customer, unlocking a
              wealth of strategic insights.
            </p>
          </div>
          {isMobile && (
            <div className="reporting-img">
              <img src={reporting} alt="reporting-img"></img>
            </div>
          )}
        </div>

        {/* Testimonials and Review */}
        <div
          className="testimonials-view"
          ref={testimonialsRef}
          data-aos="fade-up"
        >
          <div className="tag">Testimonials and Reviews</div>

          <strong
            className="section-heading"
            style={{ padding: isMobile ? '7% 0' : '2% 0', textAlign: 'center' }}
          >
            What do they say about us
          </strong>
          <Slider {...videoSettings} className="slider">
            {renderTestimonials()}
          </Slider>
        </div>

        {/* FAQS */}
        <div className="faqs" ref={faqsRef} data-aos="fade-up">
          <div className="tag">What Customers Often Ask</div>

          <strong
            className="section-heading"
            style={{
              padding: isMobile ? '7% 0 0 0' : '2% 0',
              textAlign: 'center',
            }}
          >
            Frequently Asked Questions
          </strong>
          <div className="faqs-accordion">
            <Accordion
              flush
              activeKey={activeAccordionItem}
              onSelect={handleAccordionToggle}
            >
              {renderFAQs()}
            </Accordion>
          </div>
        </div>
      </div>

      {/* Schedule Demo */}
      <div className="demo-banner">
        <div className="wrapper" data-aos="fade-up">
          <h2 className="banner-title">Revolutionize your customer support!</h2>
          <p className="banner-heading">
            Boost retention, slash refunds, improve profitability.
          </p>
          <p className="banner-paragraph">
            Time to level up and boost your bottom line!{' '}
            <img src={underline} alt="underline" />
          </p>
        </div>
        <div className="demo-button-wrapper" data-aos="fade-up">
          <button onClick={handleBooking}>Let's Chat!</button>
        </div>
      </div>
    </>
  );
};

interface Feature {
  id: number;
  heading: string;
  gif: string;
  gifClosed: string;
  paragraph: string;
}

export default Home;
