import "../../styles/DemoVideoModal.scss";
import { useState } from "react";
import { Modal } from "react-bootstrap";

const DemoVideoModal = () => {
  const [showModal, setShowModal] = useState(true);

  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
        <Modal.Body className="container">
          <video controls autoPlay width="100%">
            <source
              src="https://solvpath.com/solvpath-sizzle.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DemoVideoModal;
