import { FAQ, Testimonial } from './models';
const customerPortalGif: string =
  require('../assets/gifs/customer-portal.gif') as string;
const retentionGif: string = require('../assets/gifs/retention.gif') as string;
const helpDeskGif: string = require('../assets/gifs/help-desk.gif') as string;
const callUrlGif: string = require('../assets/gifs/call-url.gif') as string;

const petjoy: string =
  require('../assets/testimonial-assets/logos/petjoy.png') as string;
const petjoyThumbnail: string =
  require('../assets/testimonial-assets/video-thumbnails/pet-joy.png') as string;
const greenApe: string =
  require('../assets/testimonial-assets/logos/green-ape.png') as string;
const greenApeThumbnail: string =
  require('../assets/testimonial-assets/video-thumbnails/green-ape.png') as string;
const redHot: string =
  require('../assets/testimonial-assets/logos/red-hot.png') as string;
const redHotThumbnail: string =
  require('../assets/testimonial-assets/video-thumbnails/red-hot.png') as string;
const scaleThumbnail: string =
  require('../assets/testimonial-assets/video-thumbnails/scale-operations.png') as string;
const seraLabs: string =
  require('../assets/testimonial-assets/logos/sera-labs.png') as string;
const seraThumbnail: string =
  require('../assets/testimonial-assets/video-thumbnails/sera-labs.png') as string;
const tacticalThumbnail: string =
  require('../assets/testimonial-assets/video-thumbnails/tactical-brotherhood.png') as string;
const westmount: string =
  require('../assets/testimonial-assets/logos/west-mount.png') as string;
const westmountThumbnail: string =
  require('../assets/testimonial-assets/video-thumbnails/westmount.png') as string;

const customerPortalClosed: string =
  require('../assets/customer-portal-closed.png') as string;
const rententionClosed: string =
  require('../assets/retention-closed.png') as string;
const helpDeskClosed: string =
  require('../assets/help-desk-closed.png') as string;
const callUrlClosed: string =
  require('../assets/call-url-closed.png') as string;

export const features = [
  {
    id: 1,
    heading: 'Easy Order and Subscription Management',
    gif: customerPortalGif,
    gifClosed: customerPortalClosed,
    paragraph:
      'Simplify order and subscription management, enabling customers to effortlessly handle their subscriptions and orders through an intuitive and user-friendly customer portal.',
  },
  {
    id: 2,
    heading: 'Customize Support Paths for Improved Retention',
    gif: retentionGif,
    gifClosed: rententionClosed,
    paragraph:
      'Select from a curated gallery of downsell flows, specifically crafted to retain customers, deflect returns, reduce refunds, and improve overall profitability.',
  },
  {
    id: 3,
    heading: 'AI Powered Help Desk ',
    gif: helpDeskGif,
    gifClosed: helpDeskClosed,
    paragraph:
      'Our intelligent, AI-driven help desk empowers customers to easily find the answers they seek. Additionally, in more complex scenarios, witness solvpath seamlessly automating requests that would typically require live assistance.',
  },
  {
    id: 4,
    heading: 'Convert Inbound Calls to Digital Support ',
    gif: callUrlGif,
    gifClosed: callUrlClosed,
    paragraph:
      'solvpath turns inbound calls into a seamless self-service experience. Opt for live agent assistance or go digital, transforming costly calls into an efficient and user-friendly support solution for customers.',
  },
];

export const images = {
  minusLogo: '../assets/minus.png',
  plusLogo: '../assets/plus.png',
  retention: '../assets/Gifs/retention.gif',
  retentionClosed: '../assets/Gifs/retention-closed.gif',
};

export const testimonials: Testimonial[] = [
  {
    id: 'petJoy',
    videoUrl: 'https://solvpath.com/testimonial_petjoy.mp4',
    quote:
      '“It allows us to mimic the perfect customer support agent to get the best possible outcome.”',
    clientName: 'Brian Murphy',
    clientDesignation: 'Chief Operating Officer',
    logo: petjoy,
    thumbnail: petjoyThumbnail,
    isPlaying: false,
  },
  {
    id: 'seraLabs',
    videoUrl: 'https://solvpath.com/testimonial_sera.mp4',
    quote:
      '“solvpath does a great job with downselling, saving sales and subscribers. We couldn’t be happier!”',
    clientName: 'Nancy Duitch',
    clientDesignation: 'CEO',
    logo: seraLabs,
    thumbnail: seraThumbnail,
    isPlaying: false,
  },
  {
    id: 'redHot',
    videoUrl: 'https://solvpath.com/testimonial_redhot.mp4',
    quote:
      '“Using solvpath we’ve seen a reduced burden on our call center, along with a reduction in disputes and refunds by allowing a self-service option for our customers.”',
    clientName: 'Matt Juszczak',
    clientDesignation: 'CTO',
    logo: redHot,
    thumbnail: redHotThumbnail,
    isPlaying: false,
  },
  {
    id: 'greenApe',
    videoUrl: 'https://solvpath.com/testimonial_kyle.mp4',
    quote:
      '“solvpath has increased our customer satisfaction, and considerably lowered our refunds and customer support costs.”',
    clientName: 'Kyle Grider',
    clientDesignation: 'Founder',
    logo: greenApe,
    thumbnail: greenApeThumbnail,
    isPlaying: false,
  },
  {
    id: 'westmount',
    videoUrl: 'https://solvpath.com/testimonial_westmountliam720.mp4',
    quote: '“solvpath is raising the bar in the customer services game.”',
    clientName: 'Liam Birch',
    clientDesignation: 'Director',
    logo: westmount,
    thumbnail: westmountThumbnail,
    isPlaying: false,
  },
  {
    id: 'scaleOperations',
    videoUrl: 'https://solvpath.com/testimonial_scaleops.mp4',
    quote:
      '“I love how customizable it is, and how granular the data is to help us with improving customers performance.”',
    clientName: 'Andrew Ukpabi',
    clientDesignation: 'Founder',
    companyName: 'Scale Operations Mgt',
    thumbnail: scaleThumbnail,
    isPlaying: false,
  },
  {
    id: 'tacticalBrotherhood',
    videoUrl: 'https://solvpath.com/testimonial_sam.mp4',
    quote:
      '“solvpath changed the game for us when it came to customer service.”',
    clientName: 'Sam Sakievich',
    clientDesignation: 'CEO',
    companyName: 'Team Strategery',
    thumbnail: tacticalThumbnail,
    isPlaying: false,
  },
];

export const faqs: FAQ[] = [
  {
    question: 'Why should my business use solvpath?',
    answer:
      "solvpath is your secret weapon for super-smooth customer support. It's a visual order and subscription management portal, that acts like a smart support agent, saving your sales, keeping subscribers happy, and lowering refunds. Plus, it cuts down customer frustration, in return reducing disputes. Using solvpath isn't just smart—it's a direct path to saving and making more money for your business.",
  },
  {
    question: 'Is solvpath a ticketing system?',
    answer:
      "No, solvpath is not a ticketing system. solvpath integrates seamlessly alongside ticketing systems to enhance customer support efficiency. Rather than acting as a standalone ticketing solution, solvpath's primary focus is on deflecting email and social tickets from opening and reducing the need for extensive back-and-forth communication. It aims to minimize consumer frustration by empowering users with self-service options.",
  },
  {
    question: 'Do I still need a call center for my business?',
    answer:
      'While solvpath excels at automating a good percentage of routine inquiries and complex scenarios, having a call center is invaluable for addressing urgent matters and more pressing issues. This dynamic duo ensures a well-rounded support system, optimizing efficiency and responsiveness in managing diverse customer needs.',
  },
  {
    question: 'Will solvpath save my business money?',
    answer:
      "While we're confident you'll see quick cost reductions (typically 50-65% in customer support costs), the real money-making potential lies in other avenues. Consider this: improve your retention rate by an extra 5-10%, deflect cancels and returns by 5-7%, and benefit from proven data showing that merchants using self-service tools reduce disputes and refunds by an additional 4-10%. The numbers speak for themselves – saving money with solvpath is just the beginning; it's a formula for making more money.",
  },
  {
    question: 'How much does solvpath cost?',
    answer:
      'Our charges are based on a per support inquiry (session) model, starting as low as $0.50 and reaching a maximum of $1.25. Your specific rate is determined by your chosen plan and monthly volume.',
  },
  {
    question: 'How long does setup take?',
    answer:
      'Setting up solvpath is a breeze, tailored to your unique sales funnels, campaign details, and the number of products you want to sync. While the specifics may vary, we generally get your campaign up and running within just 5 business days. Your efficiency is our priority!',
  },
];
